import PropTypes from 'prop-types'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Context

Sentry.init({
	dsn: process.config.sentry?.dsn,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});

export const SentryProvider = ({ children }) => {	
	return children;
}

SentryProvider.propTypes = {
	children: PropTypes.node,
}

export default SentryProvider;
